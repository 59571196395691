import {
  Grommet,
  Iteration,
  Money,
  DocumentStore,
  Schedule,
  Radial,
  Transaction as TransactionIcon,
  Template,
} from 'grommet-icons';
import styled from 'styled-components';
import {
  DASHBOARD,
  PROGRAM_ROOT,
  ADMIN_ROOT,
  DATA_ROOT,
  // BOM_ROOT,
  COST_ROOT,
  QUOTE_ROOT,
  VARIANCE_ROOT,
  REPORT_ROOT,
  QUOTE_MANUFACTURING_BOM_VIEW,
  QUOTE_VARIANCE,
  // QUOTES_SCHEDULES,
  QUOTES_SCHEDULES_ROOT,
  REPORT_DETAILS,
} from './routes';

const {
  REACT_APP_SAME_ORIGIN_API,
  REACT_APP_API_HOST,
  REACT_APP_QNP_PORT,
  REACT_APP_CALCULATIONS_PORT,
  REACT_APP_COLLABORATION_PORT,
  REACT_APP_REBATE_PORT,
  REACT_APP_REPORT_PORT,
  REACT_APP_UAM_API,
  REACT_APP_NPI_ENVIRONMENT,
} = process.env;
const { protocol, origin } = window.location;
export const VERSION_KEY = 'qbert2-uat-release-1.0.7';
export const VALID_EXTENSIONS_DATA_UPLOAD = ['csv'];
export const UAM_API = REACT_APP_UAM_API;
export const BASE_URL = JSON.parse(REACT_APP_SAME_ORIGIN_API)
  ? origin
  : `${protocol}//${REACT_APP_API_HOST}`;

export const QNP = REACT_APP_QNP_PORT ? `:${REACT_APP_QNP_PORT}` : '/qnp';
export const COLLABORATION = REACT_APP_COLLABORATION_PORT
  ? `:${REACT_APP_COLLABORATION_PORT}`
  : '/collaboration';
export const CALCULATIONS = REACT_APP_CALCULATIONS_PORT
  ? `:${REACT_APP_CALCULATIONS_PORT}`
  : '/calculations';
export const REBATE = REACT_APP_REBATE_PORT
  ? `:${REACT_APP_REBATE_PORT}`
  : '/rebate';
export const REPORT = REACT_APP_REPORT_PORT
  ? `:${REACT_APP_REPORT_PORT}`
  : '/reports';
export const Transaction = styled(TransactionIcon)`
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
`;

export const REDUCER_VARIANCES_TYPES = {
  MANUFACTURING: 'manufacturing',
  PROCUREMENT: 'procurement',
  DISTRIBUTION: 'distribution',
  COST: 'varianceCost',
  QUANTITY: 'varianceQuantity',
  BOM: 'bom',
};

export const BOM_VIEW_NEGOTIATED_COST = 'Negotiated Cost';
export const COST_SOURCE = 'Cost Source';
export const REDUCERS = {
  LOADING: { loading: true, error: false },
  SUCCESS: { loading: false, error: false },
  ERROR: { loading: false, error: true },
};

export const USER_ROLES = {
  SUPER_USER: 'qbert2-admin',
  SPM: 'spm',
  SUPPLIER: 'supplier',
  COMMODITIY_MANAGER: 'cm',
  COST_ANALIST: 'ca',
  FINANCIAL_ADMIN: 'finadmin',
  REPORTVIEWER_NEGOTIATEDCOST: 'reportviewer-negotiatedcost',
};

export const dashboardRoutes = [
  {
    path: DASHBOARD,
    name: 'Dashboard',
    icon: Template,
    requiredUserRoles: [
      USER_ROLES.SUPER_USER,
      USER_ROLES.SUPPLIER,
      USER_ROLES.SPM,
      USER_ROLES.COMMODITIY_MANAGER,
      USER_ROLES.COST_ANALIST,
      USER_ROLES.FINANCIAL_ADMIN,
    ],
  },
  {
    path: PROGRAM_ROOT,
    name: 'Program',
    icon: Iteration,
    requiredUserRoles: [
      USER_ROLES.SUPER_USER,
      USER_ROLES.SUPPLIER,
      USER_ROLES.SPM,
      USER_ROLES.COMMODITIY_MANAGER,
      USER_ROLES.COST_ANALIST,
      USER_ROLES.FINANCIAL_ADMIN,
    ],
  },
  {
    path: VARIANCE_ROOT,
    name: 'Variance',
    icon: Transaction,
    requiredUserRoles: [
      USER_ROLES.SUPER_USER,
      USER_ROLES.SUPPLIER,
      USER_ROLES.SPM,
      USER_ROLES.COMMODITIY_MANAGER,
      USER_ROLES.COST_ANALIST,
    ],
    compoundPaths: [QUOTE_VARIANCE],
  },
  {
    path: QUOTE_ROOT,
    name: 'Quotes',
    icon: DocumentStore,
    requiredUserRoles: [
      USER_ROLES.SUPER_USER,
      USER_ROLES.SUPPLIER,
      USER_ROLES.SPM,
      USER_ROLES.COMMODITIY_MANAGER,
      USER_ROLES.COST_ANALIST,
    ],
    compoundPaths: [QUOTE_MANUFACTURING_BOM_VIEW],
  },
  /* {
    path: BOM_ROOT,
    name: 'BOM',
    icon: Money,
    requiredUserRoles: [],
    compoundPaths: [QUOTE_MANUFACTURING_BOM_VIEW],
  }, */
  {
    path: COST_ROOT,
    name: 'Cost',
    icon: Money,
    requiredUserRoles: [
      USER_ROLES.SUPER_USER,
      USER_ROLES.SPM,
      USER_ROLES.FINANCIAL_ADMIN,
      USER_ROLES.COMMODITIY_MANAGER,
      USER_ROLES.COST_ANALIST,
      USER_ROLES.SUPPLIER,
    ],
  },
  {
    path: QUOTES_SCHEDULES_ROOT,
    name: 'Calendar',
    icon: Schedule,
    requiredUserRoles: [
      USER_ROLES.SUPER_USER,
      USER_ROLES.COMMODITIY_MANAGER,
      USER_ROLES.COST_ANALIST,
    ],
  },
  {
    path: DATA_ROOT,
    name: 'Data',
    icon: Money,
    requiredUserRoles: [
      USER_ROLES.SUPER_USER,
      USER_ROLES.SUPPLIER,
      USER_ROLES.SPM,
      USER_ROLES.COMMODITIY_MANAGER,
    ],
  },
  {
    path: ADMIN_ROOT,
    name: 'Admin',
    icon: Grommet,
    requiredUserRoles: [
      USER_ROLES.SUPER_USER,
      USER_ROLES.FINANCIAL_ADMIN,
      USER_ROLES.SPM,
      USER_ROLES.COST_ANALIST,
    ],
  },
  {
    path: REPORT_ROOT,
    name: 'Reports',
    icon: Radial,
    requiredUserRoles: [
      USER_ROLES.SUPER_USER,
      USER_ROLES.SUPPLIER,
      USER_ROLES.SPM,
      USER_ROLES.COMMODITIY_MANAGER,
      USER_ROLES.COST_ANALIST,
      USER_ROLES.REPORTVIEWER_NEGOTIATEDCOST,
    ],
    compoundPaths: [REPORT_DETAILS],
  },
];

export const COST_INCREASE = 'Cost increase';
export const COST_DECREASE = 'Cost decrease';

export const NEW_MATERIAL = 'new';
export const DELETED_MATERIAL = 'deleted';
export const QUANTITY_CHANGE = 'quantity';

export const QUANTITY_VARIANCE_TYPES = [
  { id: 'new', value: 'New Material' },
  { id: 'deleted', value: 'Deleted Material' },
  { id: 'quantity', value: 'Quantity Change' },
  { id: 'Alternate Material', value: 'Alternate Material' },
];

export const BOM_OWNER_ROLE_TYPES = [
  { id: 'HPE', value: 'HPE' },
  { id: 'Supplier', value: 'Supplier' },
  { id: 'Integrated', value: 'Integrated' },
];

export const VARIANCES_VIEW_ALL_TEXT = 'View variances assigned to all roles';
export const VARIANCES_JUST_ME_TEXT = 'View variances assigned to me';

export const ITEMS_OWNED_BY = 'View items owned by me';
export const ITEMS_OWNED_BY_ALL = 'View all items';

export const PROGRAMS_VIEW_ALL_TEXT = 'View all programs';
export const PROGRAMS_JUST_ME_TEXT = 'View programs assigned to me';

export const COST_VARIANCE_TYPES = [
  { id: COST_INCREASE, value: COST_INCREASE },
  { id: COST_DECREASE, value: COST_DECREASE },
];

export const COST_SOURCE_TYPES_NPI = [
  { id: 'ABC', value: 'TK QoQ' },
  { id: 'Calculated - XR', value: 'Calculated - XR' },
  { id: 'Prior Approved', value: 'Prior Approved' },
  { id: 'PCC', value: 'PCC' },
  { id: 'Calculated - Conditional', value: 'Calculated - Conditional' },
  { id: 'MATERIAL NOT FOUND', value: 'Material not found' },
  { id: 'UI', value: 'UI' },
  { id: 'Current quote', value: 'Current quote' },
  { id: 'Sell', value: 'Sell' },
  { id: 'Calculated - BOM rollup', value: 'Calculated - BOM rollup' },
  { id: 'Calculated - FB', value: 'Calculated - FB' },
  { id: 'Calculated - Other', value: 'Calculated - Other' },
  { id: 'Calculated - Distributed', value: 'Calculated - Distributed' },
  { id: 'Child quote', value: 'Child quote' },
  { id: 'No Cost Found', value: 'No Cost Found' },
  { id: 'GTK', value: 'GTK' },
  { id: 'Sust Child quote', value: 'Sust Child quote' },
];
export const COST_SOURCE_TYPES = [
  { id: 'ABC', value: 'TK QoQ' },
  { id: 'Calculated - XR', value: 'Calculated - XR' },
  { id: 'Prior Approved', value: 'Prior Approved' },
  { id: 'PCC', value: 'PCC' },
  { id: 'Calculated - Conditional', value: 'Calculated - Conditional' },
  { id: 'MATERIAL NOT FOUND', value: 'Material not found' },
  { id: 'UI', value: 'UI' },
  { id: 'Current quote', value: 'Current quote' },
  { id: 'Sell', value: 'Sell' },
  { id: 'Calculated - BOM rollup', value: 'Calculated - BOM rollup' },
  { id: 'Calculated - FB', value: 'Calculated - FB' },
  { id: 'Calculated - Other', value: 'Calculated - Other' },
  { id: 'Calculated - Distributed', value: 'Calculated - Distributed' },
  { id: 'Child quote', value: 'Child quote' },
  { id: 'No Cost Found', value: 'No Cost Found' },
  { id: 'GTK', value: 'GTK' },
];
export const COST_SEQUENCE_SOURCE_TYPES = [
  { id: 'Procured', value: 'Procured' },
  { id: 'Supplier specified', value: 'Supplier specified' },
  { id: 'Calculated - BOM rollup', value: 'Calculated - BOM rollup' },
  { id: 'Calculated - FB', value: 'Calculated - FB' },
  { id: 'Calculated - Other', value: 'Calculated - Other' },
  { id: 'Calculated - Distributed', value: 'Calculated - Distributed' },
  { id: 'Calculated - XR', value: 'Calculated - XR' },
  { id: 'Calculated - Conditional', value: 'Calculated - Conditional' },
  { id: 'Calculated - Product', value: 'Calculated - Product' },
  { id: 'Calculated - Sourced', value: 'Calculated - Sourced' },
];

export const PROGRAM_TYPES = [
  { id: 'CTO', name: 'CTO' },
  { id: 'BTO', name: 'BTO' },
];

export const calculatedRegex = /Calculated -/g;

export const QUOTE_TYPES = [
  { id: 'L5/L6', name: 'L5/L6' },
  { id: 'BTO L10', name: 'BTO L10' },
  { id: 'CTO L10', name: 'CTO L10' },
];

export const OPERATION_TYPES = [
  { id: 'Distribution', name: 'Distribution' },
  { id: 'Procurement', name: 'Procurement' },
  { id: 'Manufacturing', name: 'Manufacturing' },
];

export const UI_BEHAVIOR = [
  { id: 'normal', name: 'normal' },
  { id: 'hidden', name: 'hidden' },
  { id: 'highlight', name: 'highlight' },
];

export const MATERIAL_TYPES = []; // ANFTODO ADD MATERIAL TYPES ENUM WHEN ADDED TO THE BE

export const DATA_TYPES = [
  { id: 'program-materials', name: 'Program Materials' },
  { id: 'bom', name: 'BOM' },
  { id: 'material-partner-costs', name: 'Quoted Costs' },
  // Negotiated cost information will be fetched from the data-lake
  // { id: 'material-negotiated-costs', name: 'Material Negotiated Costs' },
];

export const DATA_TYPES_DISPLAY_ENUM = {
  BOM: 'BOM',
  ProgramMaterial: 'Program Materials',
  PartnerCost: 'Quoted Costs',
  NegotiatedCostData: 'Material Negotiated Costs',
  MaterialRebateCost: 'Material Rebate Costs',
};

export const REBATE_TYPES = [
  { id: 1, name: 'SPECIAL' },
  { id: 2, name: 'COMBO' },
];

export const DATE_FORMAT_STRINGS = {
  SHORT_YEAR_FORMAT: 'YYYY-MM-DD',
  COMPLETE_DATE_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSSSZ',
  FORM_DATE_FORMAT: 'MM/DD/YYYY',
  SHORT_DATE_FORMAT: 'YYYY-MM-DD',
  TABLE_DATE_FORMAT: 'MMMM DD, YYYY',
  TABLE_DATE_TIME_FORMAT: 'MMMM DD, YYYY hh:mm a',
  SHORT_MONTH_FORMAT: 'MMMM YYYY',
};

export const DATA_UPLOADS_STATUS_LIST = {
  UPLOADED: 'UPLOADED',
  UPLOADING: 'UPLOADING',
  FAILED: 'FAILED',
  FINISHED: 'FINISHED',
  PROCESSING: 'PROCESSING',
  CHECKED: 'CHECKED',
  UNCHECKED: 'UNCHECKED',
  DONE: 'DONE',
  ERROR: 'ERROR',
};

export const MESSAGES = {
  FORM_SUBMITTED_SUCCESSFULLY: 'Form submitted successfully.',
  FORM_UPDATED_SUCCESSFULLY: 'Form updated successfully.',
  DATA_DELETED_SUCCESSFULLY: 'Data deleted successfully.',
  SOMETHING_WENT_WRONG: 'Something went wrong.',
  FILE_UPLOADED_SUCCESSFULLY: 'File uploaded successfully.',
  FILE_INCORRECT_FORMAT:
    'The file you try to upload is not on .CSV format. Please try again.',
  FILE_SIZE_LIMIT: 'The file exceeds the size limit allowed',
  REPORT_REQUEST_SUBMIIITED: 'Report request submitted successfully.',
};

export const FILE_EXTENSION_REGEX = /[^.]+$/g;

export const MENU_OPTIONS = {
  GENERATE_QUOTES: 'Generate Quotes',
  GENERATE_REBATES: 'Generate Rebates',
};
export const PAGE_SIZE = [40, 60, 80, 100];

export const DEFAULT_PAGINATION_OBJECT = {
  pageNumber: 1,
  pageSize: 40,
  allItems: false,
  infinite: false,
};

export const ACCESS_TOKEN = 'ckAccessToken';
export const REFRESH_TOKEN = 'ckRefreshToken';
export const ROLES = 'roles';
export const FILE_SIZE_LIMIT = {
  CSV: 50000000,
};

export const VARIANCE_RESOLUTION_ACTIONS = {
  'cost-decreased': 'Cost Decreased',
  'cost-decreased-still-above': 'Cost Decreased Still Above',
  'cost-decreased-accepted': 'Cost Decreased Accepted',
  'cost-increased': 'Cost Increased',
  'variance-approved-manually': 'Variance Approved Manually',
  'variance-rejected-manually': 'Variance Rejected Manually',
  'material-deleted': 'Material Deleted',
  'material-added': 'Material Added',
  'material-qty-changed': 'Material Qty Changed',
  'material-qty-reversed': 'Material Qty Reversed',
  'no-changes': 'No Changes',
};

export const ALL_PLANT_OBJ = { code: 'All', id: 'ALL' };
export const ALL_PROGRAM_OBJ = { name: 'All', id: 'ALL' };

export const REPORTS_ASSIGNED = {
  SUPPLIER: [
    'BOM where-used',
    'Material cost & trending analysis',
    'Quote Download',
    'Variance update history',
    'Negotiated Cost',
    'Structured Quote',
    'Quote Variance',
  ],
  SPM: [
    'BOM where-used',
    'Material cost & trending analysis',
    'Quote Download',
    'CCS template',
    'Material Lowest Costs',
    'Variance update history',
    'Component cost',
    'Costed Bom Details',
    'Costed Bom Summary',
    'Negotiated Cost',
    'Structured Quote',
    'Quote Variance',
  ],
  CA: [
    'S4 template',
    'Component cost',
    'Costed Bom Details',
    'Costed Bom Summary',
    'BOM where-used',
    'Material cost & trending analysis',
    'Quote Download',
    'Variance update history',
    'CCS template',
    'Material Lowest Costs',
    'Negotiated Cost',
    'Structured Quote',
    'Quote Variance',
  ],
  ADMIN: [
    'BOM where-used',
    'Material cost & trending analysis',
    'Negotiated Cost',
    'Quote Download',
    'CCS template',
    'Material Lowest Costs',
    'Variance update history',
    'S4 template',
    'Component cost',
    'Costed Bom Details',
    'Costed Bom Summary',
    'Structured Quote',
    'Quote Variance',
    'Master Data',
  ],
  REPORTVIEWER_NEGOTIATEDCOST: ['Negotiated Cost'],
};

export const AUTHORIZED_ACCESS_TO_REPORT =
  'You are not authorized to view this report.';

export const NEGOTIATED_COST_TYPES = [
  { id: 'sell', name: 'Sell' },
  { id: 'gtk', name: 'GTK' },
  { id: 'nec-sell', name: 'NEC Sell' },
  { id: 'tk-qoq', name: 'TK QoQ' },
];

export const COST_CATEGORY_OPTION = {
  ABC: 'ABC',
  TK_QoQ: 'TK QoQ',
};

export const NEGOTIATED_COST_SOURCE_TYPES = [
  { id: 'ABC', value: 'TK QoQ' },
  { id: 'GTK', value: 'GTK' },
  { id: 'Sell', value: 'Sell' },
];

export const DEFAULT_COST_SEQUENCE_TYPES = [
  { id: 'L5/L6', name: 'Default L5/L6' },
  { id: 'BTO L10', name: 'Default BTO L10' },
  { id: 'CTO L10', name: 'Default CTO L10' },
];

export const NON_MATERIAL_COST_SEQUENCE_TYPES = [
  { id: 'L5/L6', name: 'Non-Material L5/L6' },
  { id: 'BTO L10', name: 'Non-Material BTO L10' },
  { id: 'CTO L10', name: 'Non-Material CTO L10' },
];

export const convertCalculationRuleToString = val => {
  if (Array.isArray(val) || typeof val === 'object') {
    return JSON.stringify(val);
  }
  return val;
};

export const IS_NPI_ENVIROMENT = REACT_APP_NPI_ENVIRONMENT
  ? REACT_APP_NPI_ENVIRONMENT === 'true'
  : false;

export const themeColor = IS_NPI_ENVIROMENT ? '#7630EA' : '#01a982';
